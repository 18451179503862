.ResultBlocksTable {
  width: 80%;
  border-collapse: collapse;
  margin: 45px 15px 0px 15px;
}

.ResultBlocksTablethead th {
  background-color: #f2f2f2;
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.ResultBlocksTable-tbody td {
  border: 1px solid #ddd;
  padding: 8px;
}

.ResultBlocksTableTr:nth-child(even) {
  background-color: #f2f2f2;
}

.ResultBlocksTableTr:hover {
  background-color: #ddd;
}
