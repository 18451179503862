.DropDown {
  margin-top: auto;
  margin-bottom: 20px;
  padding: 12px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  background-color: white;
  color: #5a6a85;
  box-shadow: 0px 12px 34px -10px rgba(58, 77, 233, 0.15);
  border-radius: 10px;
}

.DropDown option {
  color: #5a6a85;
  padding: 12px;
  box-shadow: 0px 12px 34px -10px rgba(58, 77, 233, 0.15);
  border-radius: 10px;
}

/* Add some styling to the dropdown when it is hovered over */
.DropDown:hover {
  background-color: #f1f1f1;
}

.DropDown.form {
  margin-top: 0px;
  width: 50%;
}
