body {
  margin: 0;
  font-family: "Open Sans";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* 
:root {
  --primary-color-ultra-light: #fafcff;
  --primary-color-light: #e9f4fa;
  --primary-color: #479fdb;
  --primary-color-hover: #4aa9e8;
  --primary-color-dark: #002856;
  --primary-color-dark-hover: #14366e;
  --text-color: #6a8095;
  --line-color: #eff1f5;
  --error-text-color: #e54542;
} */
