#CreateCustomAnalysisLayersPopUp {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  z-index: 11000;
  background-color: rgba(0, 0, 0, 0.5);
  overflow-x: hidden;
  overflow-y: auto;
}

#CreateCustomAnalysisLayersPopUp_Box {
  position: absolute;
  top: 30px;
  left: calc(50% - 550px);
  background-color: white;
  border-radius: 35px;
  overflow: hidden;
  box-shadow: 0px 7px 15px 0px rgba(0, 0, 0, 0.1);
  width: 1100px;
}

#CreateCustomAnalysisLayersPopUp_ContentWrapper {
  position: relative;
  padding: 20px 30px 30px 30px;
}

#CreateCustomAnalysisLayersScreen {
  position: absolute;
  margin: auto;
  height: 100%;
  width: 100%;

  overflow-x: hidden;
  overflow-y: auto;
}

#CreateCustomAnalysisLayersScreen-ContentHolder {
  margin: 80px;
}

/* Style for the top bar container */
.top-bar {
  background-color: #fff;
  color: #5a6a85;
  padding: 20px;
  display: flex;
  justify-content: space-between;
}

/* Style for the list items inside the top bar */
.top-bar ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.top-bar li {
  margin-right: 15px;
}

.InfoTable {
  width: 30%;
  /* border-collapse: collapse; */
}

.InfoTable-tbody td {
  padding: 0px;
  text-align: left;
  vertical-align: text-top;
}
