.TextInput-Background {
  position: relative;
  height: 30px;
  line-height: 30px;

  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;

  padding: 0px 15px;
  background-color: #f3f6fe;
  color: #405765;
  font-size: 17px;
  overflow: hidden;
}

.TextInput-Background.TextInput-BackgroundFocused {
  background-color: #ecf2ff;
}

.TextInput-Input {
  position: absolute;
  appearance: none;
  -webkit-appearance: none;
  height: 100%;
  width: calc(100% - 30px); /* 30px = 2 * 15px(padding) */
  padding: 0px;
  margin: 0px;
  background-color: transparent;
  outline: none;
  border: none;
  color: #405765;
  font-size: 17px;
}

.TextInput-VisableText {
  position: absolute;
  height: 100%;
  width: calc(100% - 30px); /* 30px = 2 * 15px(padding) */
  color: #405765;
  font-size: 17px;
}

.TextInput-InvisableText {
  opacity: 0;
}

.TextInput-IllegalInputWarning {
  position: absolute;
  top: 0px;
  right: 0px;
  height: 100%;

  padding: 0px 15px 0px 10px;
  color: rgb(234, 125, 125);
  background-color: rgb(245, 245, 245);
  font-size: 13px;
}
