.MenuTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-top: 10px;
  margin-left: 20px;
}

.Icon {
  order: 1;
  margin-right: 5px;
  font-size: 20px;
  display: inline;
  text-transform: capitalize;
}

.Title {
  order: 2;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 18px;
  display: inline;
  text-transform: capitalize;
}

.MenuTitleBtn {
  order: 3;
  width: 20px;
  height: 20px;
  margin-right: 20px;
  display: flex;
  border: none;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.15);
  line-height: 4px;
  color: #ffffff;
  background-color: #5d87ff;
  text-align: center;
  align-items: center;
  border-radius: 6px;
  cursor: pointer;
  overflow: hidden;
}
.MenuTitleBtn.transparent {
  color: black;
  background-color: transparent;
  width: 25px;
  height: 25px;
}

.MenuTitleBtn:hover {
  color: white;
  background-color: #88a8ff;
}
