#UsersScreen {
  position: absolute;
  margin: auto;
  height: 100%;
  width: 100%;

  overflow-x: hidden;
  overflow-y: auto;
}

#UsersScreen-ContentHolder {
  margin: 80px;
}

/* Style for the top bar container */
.top-bar {
  background-color: #fff;
  color: #5a6a85;
  padding: 20px;
  display: flex;
  justify-content: space-between;
}

/* Style for the list items inside the top bar */
.top-bar ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.top-bar li {
  margin-right: 15px;
}

.InfoTable {
  width: 30%;
  /* border-collapse: collapse; */
}

.InfoTable-tbody td {
  padding: 0px;
  text-align: left;
  vertical-align: text-top;
}

.NewUserFormContainer {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.NewUserFormItem {
  flex: 1;
  text-align: center;
}
.NewUserFormBtn {
  max-width: 75%;
  text-align: center;
}

.Users-table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 18px;
  text-align: left;
  width: 100%;
}

.Users-table th,
.Users-table td {
  padding: 12px 15px;
  border: 1px solid #ddd;
}

.Users-table thead tr {
  background-color: #5d87ff;
  color: #ffffff;
  text-align: left;
}

.Users-table tbody tr:nth-child(even) {
  background-color: #f3f3f3;
}

.Users-table tbody tr:hover {
  background-color: #f1f1f1;
}
