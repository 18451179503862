.Button {
  position: relative;
  height: 30px;
  line-height: 30px;
  display: inline-block;
  color: #555;
  background-color: #ecf2ff;
  padding: 0px 15px;
  text-align: center;
  font-size: 16px;

  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;

  -webkit-transition: background-color, color 250ms ease-out;
  -moz-transition: background-color, color 250ms ease-out;
  -o-transition: background-color, color 250ms ease-out;
  transition: background-color, color 250ms ease-out;

  cursor: pointer;
  overflow: hidden;

}

.Button:hover {
  color: white;
  background-color: #88a8ff;
}

.Button.disabled {
  background-color: #aaa;
  cursor: default;
}
