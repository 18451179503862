#NewScenarioScreen {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;

  overflow-x: hidden;
  overflow-y: auto;
}

#NewScenarioScreen-ContentHolder {
  margin: 80px auto;
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#NewScenarioScreen-ContentHolder .TextInput-Background {
  margin: 10px 0px;
  width: 100%;
}

#NewScenarioScreen-ContentHolder .DropDown {
  display: block;
  margin: 10px 0px;
  width: 100%;
}

#NewScenarioScreen-Info {
  font-size: medium;
  margin-bottom: 14px;
  flex-wrap: wrap;
}
