#Alerts {
  position: fixed;
  bottom: 60px;
  left: 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 12000;
}

#Alerts .Alert {
  padding: 12px 35px;
  border-radius: 35px;
  /* font-family: OpenSans; */
  font-weight: 200;
  font-size: 18px;
  background-color: white;
  box-shadow: 0px 7px 15px 0px rgba(0, 0, 0, 0.1);
  color: #5a6a85;
  margin-top: 7px;
}
