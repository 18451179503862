#ScenarioResultScreen {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;

  overflow-x: hidden;
  overflow-y: auto;
}

#ScenarioResultScreen-ContentHolder {
  margin: 80px 10px 50px 60px;
}

#ScenarioResultScreen-ResultHolder {
  display: grid;
  grid-template-columns: repeat(auto-fit, 400px); /* width of the item */
  grid-auto-rows: 290px;
  gap: 30px;
}

@media (min-width: 1400px) {
  #ScenarioResultScreen-ResultHolder {
    grid-template-columns: repeat(auto-fit, 350px);
    grid-auto-rows: 250px;
  }
}
@media (min-width: 1500px) {
  #ScenarioResultScreen-ResultHolder {
    grid-template-columns: repeat(auto-fit, 375px);
    grid-auto-rows: 275px;
  }
}
@media (min-width: 1600px) {
  #ScenarioResultScreen-ResultHolder {
    grid-template-columns: repeat(auto-fit, 400px);
    grid-auto-rows: 300px;
  }
}
@media (min-width: 1700px) {
  #ScenarioResultScreen-ResultHolder {
    grid-template-columns: repeat(auto-fit, 425px);
    grid-auto-rows: 325px;
  }
}
@media (min-width: 1800px) {
  #ScenarioResultScreen-ResultHolder {
    grid-template-columns: repeat(auto-fit, 450px);
    grid-auto-rows: 350px;
  }
}
@media (min-width: 1900px) {
  #ScenarioResultScreen-ResultHolder {
    grid-template-columns: repeat(auto-fit, 500px);
    grid-auto-rows: 362px;
  }
}

@media (min-width: 2100px) {
  #ScenarioResultScreen-ResultHolder {
    grid-template-columns: repeat(auto-fit, 550px);
    grid-auto-rows: 400px;
  }
}
@media (min-width: 2400px) {
  #ScenarioResultScreen-ResultHolder {
    grid-template-columns: repeat(auto-fit, 600px);
    grid-auto-rows: 450px;
  }
}
@media (min-width: 2500px) {
  #ScenarioResultScreen-ResultHolder {
    grid-template-columns: repeat(auto-fit, 700px);
    grid-auto-rows: 500px;
  }
}
.ScenarioResultScreen-ResultItem {
  position: relative;
  background-color: rgb(253, 253, 255);
  border-radius: 20px;
  box-shadow: 0px 7px 7px 0px rgba(0, 0, 0, 0.15);
  overflow-x: hidden;
  overflow-y: hidden;
  padding: 0; /* Remove any padding to avoid scrollbar */
  margin: 0; /* Remove any margin to avoid scrollbar */
}

.ScenarioResultScreen-ResultItemTitle {
  position: absolute;
  font-size: 70%;
  padding: 7px 12px;
  z-index: 1000;
  font-weight: bold;
  color: #555;
}

.ScenarioResultScreen-Tab {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center the buttons vertically */
  gap: 10px; /* Space between buttons */
  background-color: rgba(93, 135, 255, 0.3);
  padding: 10px 5px;
  margin: 0;
}

.tooltip .ScenarioResultScreen-Icon-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 20px; /* Adjust size as needed */
  padding: 4px;
  border-radius: 50%;
  transition: background-color 0.3s ease;
}

.ScenarioResultScreen-Icon-button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.info-box {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.tooltip {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip .tooltiptext {
  position: absolute;
  z-index: 1;
  display: none;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 5px;
  white-space: nowrap;
  transform-origin: bottom right;
  bottom: 1.5em;
  right: 0.25em;
  margin-left: -0.5em;
  transition: opacity 0.3s;
  z-index: 1000;
}

.tooltip:hover .tooltiptext {
  display: block;
  opacity: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

.spinner {
  margin-top: 27px;
  width: 19px;
  height: 19px;
  border: 5px solid rgba(0, 0, 0, 0.1);
  border-top-color: #5d87ff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.upToDate {
  margin-top: 27px;
  width: 29px;
  height: 29px;
  border-radius: 50%;
  text-align: center;
  line-height: 32px;
  background-color: rgb(240, 240, 240);
  color: #5d87ff;
}

.outOfDate {
  /* background-color: rgb(255, 215, 163); */
  background-color: #ff5d5d;
  color: white;
}
