#MessagePopup {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);

  z-index: 10000;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#MessagePopup-Section {
  background-color: white;

  border: 2px solid var(--line-color);

  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  padding: 10px;
}

#MessagePopup-Section #MessagePopup-Title {
  text-align: center;
  margin: 10px 10px 0px 10px;
  max-width: 500px;
  font-weight: bold;
  color: var(--error-text-color);
  font-size: 20px;
}

#MessagePopup-Section #MessagePopup-Text {
  text-align: left;
  margin: 10px 10px 0px 10px;
  max-width: 500px;
  white-space: pre-wrap; /* This will add a new line with \n */
}

#MessagePopup-Section #MessagePopup-Buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 10px 0px 10px 0px;
}

#MessagePopup-Section #MessagePopup-Buttons #ButtonList-ButtonHolder {
  margin: 10px 0px 0px 10px;
}

#MessagePopup-table {
  border-collapse: collapse;
  width: 90%;
}

#MessagePopup-th,
#MessagePopup-td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}
