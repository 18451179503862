#NewProjectScreen {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 90%;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
}

#NewProjectScreen-ContentHolder {
  width: 80%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: center;

  color: black;
  font-size: 24px;
}

.center-width {
  margin: auto;
}
.button-container {
  display: flex;
  justify-content: space-between;
  width: 90%; /* Adjust the width as needed */
  margin: auto 5px 15px 5px; /* Center the container horizontally */
}

.button-container button {
  border: 1px solid #ccc;
  box-sizing: border-box;
  background-color: white;
  color: #5a6a85;
  text-transform: uppercase;
  box-shadow: 0px 12px 34px -10px rgba(58, 77, 233, 0.15);
  border-radius: 10px;
  font-size: 13px;
  cursor: pointer;
  overflow: hidden;
  background-color: #5d87ff;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
}

.block-last {
  grid-column: span 3; /* This makes the last block span two columns */
  margin-left: auto;
  margin-right: auto;
  align-content: center;
}

.SelectButton {
  margin: 10px 0px;
  width: 300px;
}

.DropDown.form {
  margin: 10px 0px;
  width: 200px;
}

.TextInput-Background {
  width: 200px;
}

.row {
  display: flex;
  flex-wrap: wrap;
  gap: 80px;
}

.col {
  flex: 1;
}

.CheckBoxButton {
  margin-top: 10px;
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  background-color: white;
  color: #5a6a85;
  text-transform: uppercase;
  border-radius: 5px;
  font-size: 13px;
  cursor: pointer;
  overflow: hidden;
}

.CheckBoxButton:hover {
  background-color: #f1f1f1;
}

.CheckBoxButton.disabled {
  background-color: #ccc; /* Gray color for disabled state */
  color: #888; /* Change the text color for better visibility */
  cursor: not-allowed; /* Change cursor to not-allowed for disabled state */
  pointer-events: none; /* Disable pointer events for the button */
}

/* Optional: Add a different style for the disabled state on hover */
.CheckBoxButton.disabled:hover {
  background-color: #ccc;
}

.CheckBoxButton.active {
  background-color: #5d87ff; /* Change the background color when pressed */
  color: white; /* Change the text color when pressed */
}

#NewProjectScreen-SectionInfo {
  font-size: medium;
  text-align: center;
  width: 360px;
  margin-bottom: 14px;
  flex-wrap: wrap;
}
