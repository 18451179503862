#EditNetworkScreen {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;

  overflow-x: hidden;
  overflow-y: auto;
}

#EditNetworkScreen-Content {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

#EditNetworkScreen-MapHolder {
  flex-grow: 1;
  margin: 0px 50px 50px 50px;

  overflow: hidden;

  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}

#EditNetworkScreen-RoadInfo {
  position: fixed;
  right: 60px;
  bottom: 60px;
  z-index: 10000;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 7px 15px 0px rgba(0, 0, 0, 0.1);
}

#EditNetworkScreen-Table {
  padding: 10px;
}

#EditNetworkScreen-Item {
  font-size: 15px;
}

#EditNetworkScreen-Attribute {
  color: #999;
  padding-right: 10px;
}
