.MainBtn {
  margin-top: 20px;
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  background-color: white;
  color: #5a6a85;
  text-transform: uppercase;
  box-shadow: 0px 12px 34px -10px rgba(58, 77, 233, 0.15);
  border-radius: 10px;
  font-size: 13px;
  cursor: pointer;
  overflow: hidden;
  text-align: center;
}

.MainBtn:hover {
  background-color: #f1f1f1;
}
.MainBtn.OK {
  background-color: #5d87ff;
  color: white;
}
.MainBtn.Danger {
  background-color: #ff5d5d;
  color: white;
}
.MainBtn.disabled {
  background-color: #ccc; /* Gray color for disabled state */
  color: #888; /* Change the text color for better visibility */
  cursor: not-allowed; /* Change cursor to not-allowed for disabled state */
  pointer-events: none; /* Disable pointer events for the button */
}

.MainBtn.selected {
  border: 1px solid hsl(216, 80%, 48%);
  background-color: #4187ee;
  color: #ffffff;
}
 
.SelectButton {
  margin-top: 20px;
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  background-color: white;
  color: #5a6a85;
  text-transform: uppercase;
  border-radius: 10px;
  font-size: 13px;
  cursor: pointer;
  overflow: hidden;
}

.SelectButton:hover {
  background-color: #f1f1f1;
}

.SelectButton.disabled {
  background-color: #ccc; /* Gray color for disabled state */
  color: #888; /* Change the text color for better visibility */
  cursor: not-allowed; /* Change cursor to not-allowed for disabled state */
  pointer-events: none; /* Disable pointer events for the button */
}

/* Optional: Add a different style for the disabled state on hover */
.SelectButton.disabled:hover {
  background-color: #ccc;
}

.SelectButton.active {
  background-color: #5d87ff; /* Change the background color when pressed */
  color: white; /* Change the text color when pressed */
}
