#App-Container {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
}

#App-MenuBar {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 250px;
  height: 100%;
}

#App-Screen {
  position: absolute;
  top: 0px;
  right: 0px;
  height: 100%;
  width: calc(100% - 250px);
}
