#ChangeSpeedPopUp {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  z-index: 11000;
  background-color: rgba(0, 0, 0, 0.5);
  overflow-x: hidden;
  overflow-y: auto;
}

#ChangeSpeedPopUp_Box {
  position: absolute;
  top: 30px;
  left: calc(50% - 350px);
  background-color: white;
  border-radius: 35px;
  overflow: hidden;
  box-shadow: 0px 7px 15px 0px rgba(0, 0, 0, 0.1);
  width: 700px;
}

#ChangeSpeedPopUp_ContentWrapper {
  position: relative;
  padding: 20px 30px 30px 30px;
}

#ChangeSpeedPopUp_Title {
  /* font-family: OpenSans; */
  font-size: 19px;
  font-weight: 200;
  text-align: center;
}

#ChangeSpeedPopUp_Section {
  /* background-color: rgb(28, 123, 52, 0.07); */
  margin: 20px 0px 0px 0px;
  padding: 15px;
  border-radius: 20px;
}

.ChangeSpeedPopUp_Question {
  color: black;
  font-size: 15px;
  /* font-family: OpenSans; */
  font-weight: 200;
  margin: 0px 0px 10px 0px;
}

/* constinputstyling: React.CSSProperties = {
  font-weight: 200;
  background-color: rgb(28, 123, 52, 0.07);
  color: #666;
  height: 35px;
  font-size: 14px;
  border-radius: 10px;
}

constinputfocusstyling: React.CSSProperties = {
  background-color: rgb(28, 123, 52, 0.03);
} */

#ChangeSpeedPopUp_SubmitButtonHolder {
  display: flex;
  justify-content: space-evenly;
}

/* constsubmitreportbutton: React.CSSProperties = {
  margin: 15px 0px 0px 0px;
  background-color: rgb(28, 123, 52, 1);
  font-weight: 200;
  font-size: 15px;
  padding: 0px 70px;
  color: white;
}

constsubmitreportbuttonhover: React.CSSProperties = {
  background-color: rgb(28, 123, 52, 0.8);
} */
