#DocsScreen {
  position: absolute;
  margin: auto;
  height: 100%;
  width: 100%;

  overflow-x: hidden;
  overflow-y: auto;
}

#DocsScreen-ContentHolder {
  margin: 80px;
}

/* Style for the top bar container */
.top-bar {
  background-color: #fff;
  color: #5a6a85;
  padding: 20px;
  display: flex;
  justify-content: space-between;
}

/* Style for the list items inside the top bar */
.top-bar ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.top-bar li {
  margin-right: 15px;
}

.InfoTable {
  width: 30%;
  /* border-collapse: collapse; */
}

.InfoTable-tbody td {
  padding: 0px;
  text-align: left;
  vertical-align: text-top;
}
