.ResultBlockMap {
  height: 100%;
  width: 92%;
}

.polygon-label {
  background: white;
  border: 0;
  /* padding: 2px; */
  /* border-radius: 3px; */
  font-size: 12px;
}
